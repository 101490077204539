import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FaBars } from "react-icons/fa"
import { menuData } from "../../data/MenuData"
import Toggle from "../Theme/Toggle"
import logo from "../../assets/images/dazz.png"

const Navbar = props => {
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = () => {
    if (window.scrollY >= 20) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", changeNav)
    // navbarColor
    return () => window.removeEventListener("scroll", changeNav)
  }, [changeNav])

  return (
    <Nav scrollNav={scrollNav} hero={props.hero}>
      <NavLink to="/">
        <LogoImg src={logo} alt="dazzling.mx" />
      </NavLink>
      <Bars onClick={props.toggleSidebar} />
      <NavMenu>
        {menuData.desktop.map((item, index) => (
          <NavLink to={item.link} key={index}>
            {item.title}
          </NavLink>
        ))}
      </NavMenu>
      <NavBtn>
        <Toggle />
      </NavBtn>
    </Nav>
  )
}

export default Navbar

const Nav = styled.nav`
  background: ${props =>
    props.hero === true
      ? ({ scrollNav }) =>
          scrollNav ? props.theme.navBackgroundTrans : "transparent"
      : props.theme.navBackground};
  color: ${props =>
    props.hero === true ? props.theme.textWhite : props.theme.textColor};
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  z-index: 100;
  position: sticky;
  top: 0px;
  width: 100vw;
`

const LogoImg = styled.img`
  max-height: 40px;
  width: auto;
  position: relative;
  top: calc(50% - 26px);
  filter: (70%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    filter: brightness(100%);
  }
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
`

const Bars = styled(FaBars)`
  display: none;
  color: ${props => props.theme.textColor};

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 45px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
