export default {
  pageBackground: "#282c36",
  pageBackgroundAlt: "#fff",
  pageBackgroundSecondary: "#1f222a",

  textColor: "#fff",
  textColorReverse: "#000",
  textWhite: "#fff",
  titleColor: "#E8B863",
  titleColorAlt: "#fff",

  sidebarBackground: "#282c36",
  sidebarBackgroundReverse: "#E8B863",
  textSidebarHighlight: "#E8B863",

  navBackground: "#282c36",
  navBackgroundTrans: "rgba(0,0,0,0.4)",
  mainBackground: "#1f222a",
  footerBg: "#282c36",
  blogBackground: "#1f222a",

  brandBackground: "rgba(256, 256, 256, 0.1)"
};