export const menuData = {
  desktop: [
    { title: "About", link: "#about" },
    { title: "Talents", link: "#talents" },
    { title: "Artists", link: "#artists" },
    { title: "Brands", link: "#brands" },
    // { title: "Blog", link: "/blog" },
    { title: "Contact", link: "#contact" },
  ],
  mobile: [
    { title: "About", link: "#about" },
    { title: "Talents", link: "#talents" },
    { title: "Artists", link: "#artists" },
    { title: "Brands", link: "#brands" },
    // { title: "Blog", link: "/blog" },
    { title: "Contact", link: "#contact" },
    // { title: "Join Us", link: "/join_us" },
    // { title: "Work With Us", link: "/work_with_us" }
  ]
}