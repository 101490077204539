import React, { createContext, useState, useLayoutEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import darkTheme from './DarkTheme';
import lightTheme from './LightTheme';

const ThemeContext = createContext({
  theme: "dark",
  toggle: () => {},
});

export default ThemeContext;

export function ThemeProvider(props) {
  // keeps state of the current theme
  const [theme, setTheme] = useState(false);

  // paints the app before it renders elements
  useLayoutEffect(() => {
    const lastTheme = window.localStorage.getItem('appTheme');
    if (lastTheme === "light") {
      setTheme("light");
      // applyTheme(lightTheme);
    } else {
      setTheme("dark");
      // applyTheme(darkTheme);
    }
    // if state changes, repaints the app
  }, [theme]);

  // rewrites set of css variablels/colors
  // const applyTheme = theme => {
  //   const root = document.getElementsByTagName('html')[0];
  //   root.style.cssText = theme.join(';');
  // }

  const toggle = () => {
    const body = document.getElementsByTagName('body')[0];
    body.style.cssText = 'transition: background .5s ease';
    if (theme == "light") {
      setTheme("dark");
      window.localStorage.setItem('appTheme', "dark");
    } else {
      setTheme("light");
      window.localStorage.setItem('appTheme', "light");
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggle }} >
      <StyledThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
        {props.children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
