export default {
  pageBackground: "#fff",
  pageBackgroundAlt: "#282c36",
  pageBackgroundSecondary: "#f9f9f9",

  textColor: "#000",
  textColorReverse: "#fff",
  textWhite: "#fff",
  titleColor: "#E8B863",
  titleColorAlt: "#000",

  sidebarBackground: "#E8B863",
  sidebarBackgroundReverse: "#282c36",
  textSidebarHighlight: "#fff",

  navBackground: "#fff",
  navBackgroundTrans: "rgba(0,0,0,0.4)",
  mainBackground: "#fff",
  footerBg: "#fff",
  blogBackground: "#f9f9f9",

  brandBackground: "rgba(0, 0, 0, 0.1)"
};
