import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { menuData } from '../../data/FooterMenuData'
import logo from '../../assets/images/dazz.png'

const Footer = (props) => {
  return (
    <FooterContainer>
      <FooterLinksWrapper>
        <FooterDesc>
          <FooterLink to="/">
            <LogoImg
                src={logo}
                alt="dazzling.mx"
              />
          </FooterLink>
          <p>&copy;{new Date().getFullYear()} Dazzling </p>
        </FooterDesc>
        <FooterLinkItems>
          <FooterLinkTitle>Contact Us</FooterLinkTitle>
          {menuData.ContactUs.map((item, index) => (
            <FooterLink to={item.link} key={index}>
              {item.title}
            </FooterLink>
          ))}
        </FooterLinkItems>
      </FooterLinksWrapper>
      <FooterLinksWrapper>
      <FooterLinkItems>
          <FooterLinkTitle>People</FooterLinkTitle>
          {menuData.People.map((item, index) => (
            <FooterLink to={item.link} key={index}>
              {item.title}
            </FooterLink>
          ))}
        </FooterLinkItems>
        <FooterLinkItems>
          <FooterLinkTitle>Social Media</FooterLinkTitle>
          {menuData.SocialMedia.map((item, index) => (
            <FooterLink to={item.link} key={index}>
              {item.title}
            </FooterLink>
          ))}
        </FooterLinkItems>
      </FooterLinksWrapper>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer=styled.div`
  padding: 5rem calc((100vw - 1100px) / 2);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: ${props => props.theme.textColor};
  background: ${props => props.theme.footerBg};
`

const FooterDesc=styled.div`
  padding: 0 2rem;

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const LogoImg = styled.img`
  height: 50px;
  position: relative;
  top: calc(0% - 0px);
  filter: (70%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-bottom: 2rem;

  &:hover {
    filter: brightness(100%);
  }
`

const FooterLinksWrapper=styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`

const FooterLinkItems=styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;

  @media screen and (max-width: 400px) {
    padding: 1rem;
  }
`

const FooterLinkTitle=styled.h2`
  font-size: 1.1rem;
  color: #E8B863;
  margin-bottom: 16px;
`

const FooterLink=styled(Link)`
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: ${props => props.theme.textColor};

  &:hover {
    color: #E8B863;
    transition: 0.3s ease-out;
  }
`
