export const menuData = {
  text: "",
  ContactUs: [
    { title: "About", link: "#about" },
    { title: "Contact", link: "/#contact" },
    // { title: "Join Us", link: "/join_us" },
    // { title: "Work With Us", link: "/work_with_us" }
  ],
  People: [
    { title: "Talents", link: "#talents" },
    { title: "Artists", link: "#artists" },
    // { title: "Dazzling Team", link: "/team" },
    // { title: "Blog", link: "/blog" },
  ],
  SocialMedia: [
    { title: "Instagram", link: "https://www.instagram.com/dazzling_mx/" },
    { title: "Facebook", link: "https://www.facebook.com/dazzling.agency" },
    { title: "Youtube", link: "https://www.youtube.com/channel/UCmRx5DyVcxsH4svs-bT3cpw" },
    { title: "Twitter", link: "https://twitter.com/DazzlingMx" },
  ],
}