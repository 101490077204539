import React from "react"
import styled from "styled-components"
import { FaTimes } from "react-icons/fa"
import { menuData } from "../../data/MenuData"
import { Link } from "gatsby"
import Toggle from "../Theme/Toggle"

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggleSidebar}>
      <Icon onClick={toggleSidebar}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {menuData.mobile.map((item, index) => (
            <SidebarLink to={item.link} key={index}>
              {item.title}
            </SidebarLink>
          ))}
          <Toggle />
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar

const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.sidebarBackground};
  display: grid;
  align-items: center;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0%")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`

const CloseIcon = styled(FaTimes)`
  color: ${props => props.theme.textColor};
`

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

const SidebarWrapper = styled.div`
  color: ${props => props.theme.textColor};
`

const SidebarMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, calc(100vh / 8));
  text-align: center;
  max-height: 100vh;

  button {
    margin-top: 1rem;
  }
`

const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 1.5rem; */
  text-decoration: none;
  /* list-style: none; */
  transition: 0.2s ease-in-out;
  color: ${props => props.theme.textColor};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.textSidebarHighlight};
    transition: 0.2s ease-in-out;
  }
`
