import React, { useState } from "react";
import Navbar from "./Navbar/Navbar"
import Footer from "./Footer/Footer"
import Sidebar from "./Navbar/Sidebar";
import { GlobalStyle } from "../styles/GlobalStyle"
import { ThemeProvider } from './Theme/ThemeProvider'

const Layout = ({ location, children, hero }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ThemeProvider>
      <>
        <GlobalStyle />
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <Navbar toggleSidebar={toggleSidebar} location={location} hero={hero} />
        <main> {children} </main>
        <Footer />
      </>
    </ThemeProvider>
  )
}

export default Layout
